<template>
  <GmapMap
    :center="{ lat: 55, lng: 34 }"
    :zoom="3"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    ref="mapRef"
  >
    <GmapMarker
      :key="e.key"
      v-for="(e, index) in filteredDevices"
      :position="e"
      :clickable="true"
      :draggable="false"
      :icon="e.publicAvailable ? markers.green : markers.red"
      @click="openInfoWindowTemplate(index)"
    />

    <gmap-info-window
      :options="{ maxWidth: 300 }"
      :position="infoWindow.position"
      :opened="infoWindow.open"
      @closeclick="infoWindow.open = false"
    >
      <div v-html="infoWindow.template"></div>
    </gmap-info-window>

    <portal to="quickAction" class="d-flex flex-column">
      <b-form inline>
        <b-form-checkbox
          v-if="user.grantsSet.has('SYSTEM:ADMIN')"
          switch 
          v-model="displayAllDevices"
          @change="(e) => loadDevices()"
        >
        Отображать все устройства
      </b-form-checkbox>
      <quick-action
        :title="$t('MONITORING.ADD_NEW_DEVICE')"
        @click="this.onCreateNewAction"
      />
    </b-form>
    </portal>
    <!-- <b-modal
      id="modal-tariff"
      size="lg"
      ok-only
      @ok="submitModal"
      :ok-title="$t('COMMON.CHANGE')"
      no-close-on-backdrop
    >
      <strong>{{ $t("MONITORING.MODAL.SELECT") }}</strong>
      <b-form-select v-model="tariffId" :options="tariffs">
        <template v-slot:first>
          <b-form-select-option disabled value="null">{{
            $t("TEAMS.SELECT_TARIFF")
          }}</b-form-select-option>
        </template>
      </b-form-select>
      <br />
      <br />
      <strong>{{ $t("MONITORING.MODAL.INPUT") }}</strong>
      <b-form-input
        :placeholder="$t('MONITORING.MODAL.INPUT_PLACEHOLDER')"
        v-model.trim="promocode"
      >
      </b-form-input>
    </b-modal> -->
  </GmapMap>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import QuickAction from "@/view/layout/subheader/quick-action";
import Common from "../../common";
import ApiService from "@/core/services/api.service";

export default {
  name: "monitoring",
  components: { QuickAction },
  data() {
    return {
      getCatalogues: {
        resource: "/api/catalogue",
        requestType: "GET",
        requestParams: { enrich: true },
      },

      markers: {
        black: "/media/plugins/gmaps/marker_black.png",
        blue: "/media/plugins/gmaps/marker_blue.png",
        green: "/media/plugins/gmaps/marker_green.png",
        gray: "/media/plugins/gmaps/marker_gray.png",
        orange: "/media/plugins/gmaps/marker_orange.png",
        purple: "/media/plugins/gmaps/marker_purple.png",
        red: "/media/plugins/gmaps/marker_red.png",
        white: "/media/plugins/gmaps/marker_white.png",
        yellow: "/media/plugins/gmaps/marker_yellow.png",
      },
      catalogues: [],
      catalogueMap: {}, // <UUID, CatalogueDTO>
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
      devices: [],
      filteredDevices: [],
      teamId: ApiService.teamData.value,
      displayAllDevices: false,

      tariffsRequest: {
        resource:
          "/api/team/" + ApiService.teamData.value + "/available-tariffs",
        requestType: "GET",
        requestParams: {},
      },
      tariffs: [],
      tariffId: null,
      promocode: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted: async function () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.MONITORING") },
    ]);

    this.getCatalogues.requestParams.teamId = this.teamId;
    await this.$store
      .dispatch(API_REQUEST, this.getCatalogues)
      .then((response) => {
        this.catalogues = response.data;
        for (let i = 0; i < this.catalogues.length; i++) {
          const catalogue = this.catalogues[i];
          this.catalogueMap[catalogue.id] = catalogue;
        }
      })
      .catch(this.onError);

    this.$gmapApiPromiseLazy().then((google) => {
      var map = this.$refs.mapRef.$mapObject;
      // Create the DIV to hold the control and call the CenterControl()
      // constructor passing in this DIV.
      var centerControlDiv = document.createElement("div");

      centerControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
        centerControlDiv
      );

      if (this.catalogues.length > 0) {
        var catalogueControlDiv = document.createElement("div");
        var catalogueControl = this.catalogueControl(catalogueControlDiv, map);
        catalogueControlDiv.index = 2;
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
          catalogueControlDiv
        );
      }
    });

    this.loadTeams();
  },

  created: async function () {
    await this.loadTariffs();
    await this.loadDevices();
  },

  methods: {
    submitModal: function (bvModalEvt) {
      bvModalEvt.preventDefault();
      const request = {
        resource: "/api/team/" + ApiService.teamData.value + "/change-tariff",
        requestType: "POST",
        requestParams: {},
      };
      this.tariffs.forEach((t) => {
        if (t.value == this.tariffId) {
          request.dto = {
            productId: t.products[0].id,
            discountSecretCode: this.promocode,
          };
        }
      });
      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.$bvModal.hide("modal-tariff");
        })
        .catch(this.onError);
    },
    loadTariffs: function () {
      return this.$store
        .dispatch(API_REQUEST, this.tariffsRequest)
        .then((response) => {
          for (const value of response) {
            this.tariffs.push({
              value: value.id,
              text: value.nameLocalized,
              products: value.products,
              allowLogoCustomization: value.allowLogoCustomization,
              allowIpFiltrating: value.allowIpFiltrating,
              allowRequestLogs: value.allowIpFiltrating,
            });
          }
        })
        .catch(this.onError);
    },
    onCreateNewAction: function () {
      this.$router.push({ name: "device" });
    },
    loadDevices: async function () {
      const url = this.displayAllDevices
        ? "/api/device?withPublic=true&enrich=true&count=10000"
        : "/api/device?withPublic=true&enrich=true&count=10000&teamId=" + this.teamId
      return this.$store
        .dispatch(API_REQUEST, {
          resource: url,
          requestType: "GET",
        })
        .then((response) => {
          let devices = [];
          for (let i = 0; i < response.data.length; i++) {
            const device = response.data[i];
            if (!device.lat || !device.lng) continue;
            devices.push(device);
          }

          this.devices = devices;
          this.filteredDevices = devices;
        })
        .catch(this.onError);
    },

    loadTeams() {
      if (this.$route.params.id === "greeting") {
        ApiService.get("/api/profile/teams")
          .then((data) => {
            const myTeams = data.data;

            for (let i = 0; i < myTeams.length; i++) {
              let t = myTeams[i];
              if (
                t.id === this.teamId &&
                t.tariffId == "7fd5ba25-adce-4219-aec0-59c556bb5cb3" &&
                this.$route.params.id === "greeting"
              ) {
                this.tariffId = t.tariffId;
                this.$bvModal.show("modal-tariff");
                break;
              }
            }
          })
          .catch(() => {});
      }
    },

    openInfoWindowTemplate(index) {
      this.setInfoWindowTemplate(index);
      this.infoWindow.position = {
        lat: this.filteredDevices[index].lat,
        lng: this.filteredDevices[index].lng,
      };
      this.infoWindow.open = true;
    },

    catalogueControl: function (controlDiv, map) {
      // Set CSS for the control border.
      var select = document.createElement("select");

      select.style.backgroundColor = "#fff";
      select.style.border = "2px solid #fff";
      select.style.borderRadius = "3px";
      select.style.fontFamily = "Roboto,Arial,sans-serif";
      select.style.fontSize = "16px";
      select.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      select.style.cursor = "pointer";
      select.style.marginBottom = "22px";
      select.style.textAlign = "center";
      select.style.marginRight = "8px";
      select.style.lineHeight = "38px";
      controlDiv.appendChild(select);

      var optNotSelected = document.createElement("option");
      optNotSelected.text = this.$t("MONITORING.NO_CATALOGUE_SELECTED");
      optNotSelected.setAttribute("value", null);
      select.add(optNotSelected);

      for (let i = 0; i < this.catalogues.length; i++) {
        const catalogue = this.catalogues[i];
        const opt = document.createElement("option");
        opt.text = catalogue.name;
        opt.setAttribute("value", catalogue.id);
        select.add(opt);
      }

      let that = this;
      select.addEventListener("change", (event) => {
        that.filterDevices(event.target.value);
        that.infoWindow.open = false;
      });
    },

    filterDevices: function (catalogueId) {
      if (catalogueId === null || catalogueId === "null") {
        this.filteredDevices = this.devices;
        return;
      }
      this.filteredDevices = [];

      const catalogue = this.catalogueMap[catalogueId];
      const devicesToShow = {};

      for (let i = 0; i < catalogue.devices.length; i++) {
        const device = catalogue.devices[i];
        if (!device.lat || !device.lng) {
          continue;
        }

        devicesToShow[device.id] = true;
      }

      for (let i = 0; i < this.devices.length; i++) {
        const device = this.devices[i];
        if (devicesToShow[device.id]) {
          this.filteredDevices.push(device);
        }
      }
    },
    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString("ru-RU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    setInfoWindowTemplate: function (index) {
      const device = this.filteredDevices[index];

      let onlineStatus = device.online
        ? '<div style="border-radius: 50%; width: 8px; height: 8px; background-color: green; float: left; margin-left: 5px; margin-top: 3px;">&nbsp;</div>'
        : '<div style="border-radius: 50%; width: 8px; height: 8px; background-color: red; float: left; margin-left: 5px; margin-top: 3px;">&nbsp;</div>';

      let content =
        "<div>" + `<strong>${device.name}</strong> ${onlineStatus}` + "<br />";

      content += `<div><strong>${this.$t("MONITORING.TYPE")}: </strong>${
        device.typeName
      }</div>`;
      // `<div><strong>${this.$t("MONITORING.TEAM")}: </strong>${
      //   device.teamName
      // }</div>` +
      // `<div><strong>${this.$t("MONITORING.IMEI")}: </strong>${
      //   device.imei || this.$t("MONITORING.NO_DATA")
      // }</div>` +
      // `<div><strong>${this.$t("MONITORING.ACTIVE")}: </strong>${
      //   device.active ? this.$t("COMMON.YES") : this.$t("COMMON.NO")
      // }</div>`;

      if (device.deviceData && device.deviceData.parameters.length > 0) {
        content +=
          "<hr><strong>" + this.$t("MONITORING.PARAMETERS") + ":</strong><br/>";
        for (const param of device.deviceData.parameters) {
          content += `<div>${param.name}: ${param.value} ${
            param.unit || ""
          }</div>`;
        }
      }

      if (device.lastDataReceivedDt) {
        content +=
          `<div><strong>${this.$t(
            "MONITORING.LAST_DATA_RECEIVED"
          )}:</strong> ` +
          `${
            device.lastDataReceivedDt
              ? this.formatDate(device.lastDataReceivedDt)
              : "--"
          }</div>`;
      }

      content += "</div>";

      this.infoWindow.template = content;

      this.infoWindow.position = {
        lat: device.lat,
        lng: device.lng,
      };
      this.infoWindow.open = true;
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
