<template>
  <b-button
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown"
    no-caret
    right
    no-flip
    text="Actions"
    v-b-tooltip.hover="title"
  >
    <a
      :href="href"
      class="btn btn-icon"
      data-toggle="dropdown"
      @click="onClick"
    >
      <span class="svg-icon svg-icon-success svg-icon-2x">
        <!--begin::Svg Icon-->
        <inline-svg
          src="/media/svg/icons/Files/File-plus.svg"
          style="pointer-events: none"
        />
        <!--end::Svg Icon-->
      </span>
    </a>
  </b-button>
</template>

<script>
export default {
  name: "quick-action",

  props: {
    title: String,
    href: String,

    // @click
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
